export enum LeasingFocus {
  GuidedTour = 'GuidedTour',
  SelfGuidedTour = 'SelfGuidedTour',
  FillInApplication = 'FillInApplication',
}

export enum LeasingEventTypeIds {
  TextOutgoing = '6',
  EmailOutgoing = '2',
  CallOutgoing = '4',
  TextIncoming = '5',
  EmailIncoming = '1',
  CallIncoming = '3',
}
